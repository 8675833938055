exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-at-adresse-tsx": () => import("./../../../src/pages/at/adresse.tsx" /* webpackChunkName: "component---src-pages-at-adresse-tsx" */),
  "component---src-pages-at-cash-back-tsx": () => import("./../../../src/pages/at/cash-back.tsx" /* webpackChunkName: "component---src-pages-at-cash-back-tsx" */),
  "component---src-pages-at-kontakt-tsx": () => import("./../../../src/pages/at/kontakt.tsx" /* webpackChunkName: "component---src-pages-at-kontakt-tsx" */),
  "component---src-pages-at-tsx": () => import("./../../../src/pages/at.tsx" /* webpackChunkName: "component---src-pages-at-tsx" */),
  "component---src-pages-de-adresse-tsx": () => import("./../../../src/pages/de/adresse.tsx" /* webpackChunkName: "component---src-pages-de-adresse-tsx" */),
  "component---src-pages-de-kontakt-tsx": () => import("./../../../src/pages/de/kontakt.tsx" /* webpackChunkName: "component---src-pages-de-kontakt-tsx" */),
  "component---src-pages-de-tsx": () => import("./../../../src/pages/de.tsx" /* webpackChunkName: "component---src-pages-de-tsx" */),
  "component---src-pages-gastro-adresse-tsx": () => import("./../../../src/pages/gastro/adresse.tsx" /* webpackChunkName: "component---src-pages-gastro-adresse-tsx" */),
  "component---src-pages-gastro-gutschein-tsx": () => import("./../../../src/pages/gastro/gutschein.tsx" /* webpackChunkName: "component---src-pages-gastro-gutschein-tsx" */),
  "component---src-pages-gastro-kontakt-tsx": () => import("./../../../src/pages/gastro/kontakt.tsx" /* webpackChunkName: "component---src-pages-gastro-kontakt-tsx" */),
  "component---src-pages-gastro-tsx": () => import("./../../../src/pages/gastro.tsx" /* webpackChunkName: "component---src-pages-gastro-tsx" */),
  "component---src-pages-global-tsx": () => import("./../../../src/pages/global.tsx" /* webpackChunkName: "component---src-pages-global-tsx" */)
}

